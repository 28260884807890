import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'layouts'
import { ThemeContext } from 'Theme'

const DocsLayout = (props: any) => {
  const {
    data: {
      mdx: { body },
    },
  } = props
  // console.log(props, 'aa');
  return (
    <Layout>
      {/* <Helmet title={props?.data?.mdx?.frontmatter?.title}></Helmet> */}
      <Helmet
        title={props?.data?.mdx?.frontmatter?.title}
        meta={[
          { name: 'description', content: props?.data?.mdx?.frontmatter?.description },
          { property: 'og:description', content: props?.data?.mdx?.frontmatter?.description },
        ]}
      />
      <ThemeContext.Consumer>
        {([, setMode]) => {
          setMode('dark')
          return <MDXRenderer>{body}</MDXRenderer>
        }}
      </ThemeContext.Consumer>
    </Layout>
  )
}

export default DocsLayout

/**
 * Query for data for the page. Note that $id is injected in via context from
 * actions.createPage. See gatsby-node.js for more info.
 */
export const pageQuery = graphql`
  query DocsLayoutQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title,
        description
      }
      body
    }
  }
`
